import React from "react";
import ReactDOM from "react-dom";
import Layout from "components/Layout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { LangProvider } from "context";

import loadable from "@loadable/component";
const Home = loadable(() => import("./pages/Home/Home"));
const Product = loadable(() => import("./pages/Product/Product"));
const Products = loadable(() => import("./pages/Products/Products"));
const Custom = loadable(() => import("./pages/Custom/Custom"));
const Network = loadable(() => import("./pages/Network/Network"));
const Tech = loadable(() => import("./pages/Tech/Tech"));
const Contract = loadable(() => import("./pages/Contract/Contract"));
const Contact = loadable(() => import("./pages/Contact/Contact"));
const Collections = loadable(() => import("./pages/Collections/Collections"));
const NotFound = loadable(() => import("./pages/NotFound/NotFound"));

ReactDOM.render(
  <React.StrictMode>
    <LangProvider>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/products" element={<Products />} />
            <Route path="/tech" element={<Tech />} />
            <Route path="/custom" element={<Custom />} />
            <Route path="/network" element={<Network />} />
            <Route path="/collections" element={<Collections />} />
            <Route path="/contract" element={<Contract />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </LangProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
