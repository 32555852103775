import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Flex } from "rebass";
import cx from "classnames";
import { ReactComponent as Pinterest } from "assets/icons/Pinterest.svg";
import { ReactComponent as Instagram } from "assets/icons/Instagram.svg";
import { ReactComponent as Linkedin } from "assets/icons/Linkedin.svg";
import { ReactComponent as PinterestWhite } from "assets/icons/Pinterest_white.svg";
import { ReactComponent as InstagramWhite } from "assets/icons/Instagram_white.svg";
import { ReactComponent as LinkedinWhite } from "assets/icons/Linkedin_white.svg";

const useStyles = createUseStyles((theme: any) => ({
  root: {},
  icon: {
    width: "1.5rem",
    height: "1.5rem",
    maxWidth: "1.2rem",
    maxHeight: "1.5rem",
    margin: "0 0.8rem",
    opacity: 1,
    "&:hover,:focus,.active": {
      opacity: 0.8,
    },
  },
}));

type Social = "pinterest" | "instagram" | "linkedin";

interface ISocialIconProps {
  social: Social;
  white?: boolean;
}

const socialLinks = {
  linkedin: "linkedin.com",
  instagram: "instagram.com",
  pinterest: "pinterest.it",
};

const SocialIcon = ({ social, white }: ISocialIconProps) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <a
      href={`https://${socialLinks[social]}/${
        social === "pinterest"
          ? "gluce_italy"
          : social === "instagram"
          ? "gluce_italy/"
          : "company/g-luce-srl"
      }`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {social === "pinterest" ? (
        white ? (
          <PinterestWhite className={classes.icon} />
        ) : (
          <Pinterest className={classes.icon} />
        )
      ) : social === "instagram" ? (
        white ? (
          <InstagramWhite className={classes.icon} />
        ) : (
          <Instagram className={classes.icon} />
        )
      ) : white ? (
        <LinkedinWhite className={classes.icon} />
      ) : (
        <Linkedin className={classes.icon} />
      )}
    </a>
  );
};

function SocialIcons({
  className,
  white,
}: {
  className?: string;
  white?: boolean;
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Flex
      className={cx(classes.root, className)}
      justifyContent="center"
      alignItems="center"
    >
      {Object.keys(socialLinks).map((social, index) => {
        return (
          <SocialIcon key={index} social={social as Social} white={white} />
        );
      })}
    </Flex>
  );
}

export default SocialIcons;
