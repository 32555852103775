import { ReactComponent as Logo } from "assets/logo/GLuce_logo.svg";
import cx from "classnames";
import { Default, useIsMobile, useIsTablet } from "config/responsive";
import { bold } from "config/styles";
import { LangContext } from "context";
import { Lang } from "context/lang";
import { useLang } from "i18n";
import React, { useContext, useRef, useState } from "react";
import { FaBars } from "react-icons/fa";
import { createUseStyles, useTheme } from "react-jss";
import { Link, useNavigate } from "react-router-dom";
import { useClickAway, useLocalStorage } from "react-use";
import { Box, Flex, Text } from "rebass";
import SocialIcons from "./SocialIcons";

const useStyles = createUseStyles((theme: any) => ({
  root: {
    background: theme.colors.navbar,
    zIndex: 100,
    height: "6rem",
    maxWidth: "100vw",
    position: "relative",
  },
  logo: {
    cursor: "pointer",
    maxHeight: "4rem",
    maxWidth: "5rem",
  },
  menu: {
    marginLeft: "10% !important",
    "& > a": {
      margin: "0 1rem",
      color: theme.colors.darkText,
      textTransform: "uppercase",
      fontSize: "0.75rem",
      "&:hover": {
        textDecoration: "none",
        ...bold,
      },
    },
  },
  social: {
    marginRight: "1.5rem !important",
  },
  lang: {
    "& > div": {
      textTransform: "uppercase",
      color: theme.colors.darkText,
      "&:hover": {
        ...bold,
        cursor: "pointer",
      },
      "&:not(:first-child)": {
        "&:before": {
          content: "'|'",
          margin: "0 0.8rem",
          color: theme.colors.darkText,
        },
      },
    },
  },
  icon: {
    cursor: "pointer",
    position: "absolute",
    right: "1rem",
    top: "2rem",
    transition: "opacity 0.5s",
    "&:hover": {
      opacity: 0.6,
    },
  },
  transheight: {
    height: "35rem",
    transition: "height 0.3s",
  },
  mobile_menu: {
    flexDirection: "column",
    "& > a": {
      margin: "1rem 0",
    },
  },
  mobile_social: {
    margin: "3rem 0 2rem -1rem !important",
  },
}));

const MENU = [
  {
    title: "menu.product",
    to: "/products",
  },
  {
    title: "menu.collections",
    to: "/collections",
  },
  {
    title: "menu.tech",
    to: "/tech",
  },
  {
    title: "menu.network",
    to: "/network",
  },
  {
    title: "menu.custom",
    to: "/custom",
  },
  {
    title: "menu.contract",
    to: "/contract",
  },
  {
    title: "menu.contact",
    to: "/contact",
  },
];

function Navbar() {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { ctx, dispatch } = useContext(LangContext);
  const t = useLang();
  const [, setLang] = useLocalStorage("lang", ctx?.lang ?? "it");
  const [open, setOpen] = useState(false);
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const ref = useRef(null);

  useClickAway(ref, () => {
    if (open) setOpen(false);
  });

  const changeLang = (l: Lang): void => {
    dispatch({ type: "LANG", payload: l });
    setLang(l);
  };

  return (
    <Flex
      ref={ref}
      alignItems={open ? "start" : "center"}
      justifyContent={open ? "start" : "space-between"}
      flexDirection={open ? "column" : "row"}
      p={["1rem 1rem", "1.5rem 3rem"]}
      className={cx(classes.root, { [classes.transheight]: open })}
    >
      <Logo
        className={classes.logo}
        onClick={() => {
          navigate("/");
          if (open) setOpen(false);
        }}
      />

      {!isTablet && (
        <Default>
          <Box className={classes.menu}>
            {MENU.map((m, index) => (
              <Link key={index} to={m.to}>
                {t(m.title)}
              </Link>
            ))}
          </Box>

          <Flex alignItems="center">
            <SocialIcons className={classes.social} />

            <Flex className={classes.lang}>
              {["it", "en", "ru"].map((l, i) => (
                <Text
                  key={i}
                  onClick={() => changeLang(l as Lang)}
                  style={{
                    opacity: (ctx.lang as Lang) === (l as Lang) ? 1 : 0.6,
                  }}
                >
                  {l}
                </Text>
              ))}
            </Flex>
          </Flex>
        </Default>
      )}

      {(isMobile || isTablet) && (
        <FaBars
          size="2rem"
          className={cx(classes.icon)}
          onClick={() => setOpen(!open)}
        />
      )}

      {open && (
        <Flex
          flexDirection="column"
          className={cx(classes.menu, classes.mobile_menu)}
        >
          {MENU.map((m, index) => (
            <Link key={index} to={m.to} onClick={() => setOpen(false)}>
              {t(m.title)}
            </Link>
          ))}

          <SocialIcons className={classes.mobile_social} />

          <Flex className={classes.lang}>
            {["it", "en", "ru"].map((l, i) => (
              <Text
                key={i}
                onClick={() => changeLang(l as Lang)}
                style={{
                  opacity: (ctx.lang as Lang) === (l as Lang) ? 1 : 0.5,
                }}
              >
                {l}
              </Text>
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default React.memo(Navbar);
