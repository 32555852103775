import { I18N } from "i18n";
import { email, street, city, country, phone, fax, numero_verde } from "config/constants";

const it: I18N = {
  menu: {
    product: "Prodotti",
    collections: "Collezioni",
    tech: "Tech",
    network: "Rete",
    custom: "Custom",
    contract: "Contract",
    contact: "Contatti",
  },
  footer: {
    cookie: "Cookie Policy",
    privacy: "Privacy Policy",
    legal: "Note Legali",
    contact: "Contatti",
    download: "Scarica",
  },
  call_to_action: {
    contact_us: "Contattaci"
  },
  home: {
    products: {
      heading: "Materiali di alta qualità e tecniche innovative:",
      heading2: "lampade nate dal valore dell'artigianato e dalla continua ricerca",
      button: "Scopri i nostri punti luce",
    },
    areas: {
      discover: "Scopri le Collezioni",
      custom: "PERSONALIZZAZIONE E PROGETTAZIONE, PROTOTIPAZIONE E CONSULENZA, SERVIZI CONTRACT E ASSISTENZA POST VENDITA",
      custom_sub: "Scopri i servizi di G.Luce",
    },
    contacts: {
      gluce: {
        title: "Contatti G.Luce",
        gluce: "G.Luce Srl",
        street,
        city,
        country,
        phone,
        fax,
        email,
        numero_verde,
      },
    }
  },
  product: {
    specifications: "Dettagli",
    material: "Materiale:",
    dimensions: "Dimensioni:",
    weight: "Peso:",
    light_source: "Sorgente luminosa:",
    designer: "Designed by",
    datasheet: "Scheda Tecnica",
    info: {
      agua_applique: {
        description: "Colonna di luce. Agua è una applique cilindrica generatrice di un doppio fascio luminoso dalle due estremità. La struttura in ottone bronzato e il corpo in marmo Guatemala verde le donano una forma elegante e al contempo semplice.",
        material: "Ottone bronzato e marmo Guatemala verde",
        dimensions: "Ø6 x P11 x H36 cm",
        weight: "2,8 kg",
        light_source: "Scheda LED 4 W | 24 V | 425 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      agua_pendant: {
        description: "Colonna di luce. Lampada a sospensione a una luce con struttura in ottone bronzato e corpo in marmo Guatemala verde. Un pendente discreto nato dall’incontro raffinato tra materie preziose.",
        material: "Ottone bronzato e marmo Guatemala verde",
        dimensions: "Ø 6 x H32 cm – lungh. max.: 135 cm – dim. rosone: Ø 14 x H2,5 cm",
        weight: "1,7 kg",
        light_source: "Scheda LED 4 W | 24 V | 425 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      agua_pendant_3: {
        description: "Colonna di luce. Lampada a sospensione a tre luci con struttura in ottone bronzato e corpo in marmo Guatemala verde. Un pendente discreto nato dall’incontro raffinato tra materie preziose.",
        material: "Ottone bronzato e marmo Guatemala verde",
        dimensions: "Ø 6 x H32 cm – lungh. max.: 135 cm – dim. rosone: L60 x P6 x H3,5 cm",
        weight: "5,5 kg",
        light_source: "Scheda LED 12 W | 24 V | 1275 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      aura_floor: {
        description: "Elegante lampada da terra dal design minimale. La struttura in ottone arricchita dalla finitura in oro satinato chiaro lucido, rame o bronzo incontra al suo apice il paralume in vetro decorato definendo un perfetto equilibrio tra modernità e tradizione.",
        material: "Struttura in ottone con finitura oro satinato chiaro lucido, rame o bronzo. Paralume in vetro decorato.",
        dimensions: "Ø 33 x H158 cm",
        weight: "15,3 Kg",
        light_source: "Modulo LED36 W | 3900 lmn | 3000 K",
        designer: "G.Luce",
      },
      aura_table: {
        description: "Elegante lampada da tavolo dal design minimale. La struttura in ottone arricchita dalla finitura in oro satinato chiaro lucido, rame o bronzo incontra il paralume in vetro decorato definendo un perfetto equilibrio tra modernità e tradizione.",
        material: "Struttura in ottone con finitura oro satinato chiaro lucido, rame o bronzo. Paralume in vetro decorato.",
        dimensions: "Ø 33 x H50 cm",
        weight: "7,1 Kg",
        light_source: "Modulo LED 36 W | 3900 lmn | 3000 K",
        designer: "G.Luce",
      },
      balestra: {
        description: "La tensione e l'equilibrio raggiunto nella armonia di una bellezza scultorea. Balestra è una lampada da terra con base in marmo e ottone, con finitura in oro satinato chiaro lucido. Il paralume può essere realizzato in vetro satinato (opale o colorato) o in alluminio in diverse finiture.",
        material: "Base in marmo e ottone, con finitura oro satinato chiaro lucido. Paralume in vetro satinato (opale o colorato) o in alluminio in diverse finiture.",
        dimensions: "L12 x P58 x H203 cm",
        weight: "23,8 Kg",
        light_source: "Modulo LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      bridget_applique: {
        description: "Incontro tra materia preziosa e design. Bridget si illumina dal suo cuore. La lampada da parete è in ottone con finitura in oro satinato chiaro lucido e incantevoli inserti in onice tigrato o onice ambra.",
        material: "Ottone con finitura oro satinato chiaro lucido con inserti in onice tigrato o onice ambra.",
        dimensions: "L7 x P18,5 x H26 cm",
        weight: "1,3 kg",
        light_source: "LED Strip",
        designer: "G.Luce",
      },
      bridget_floor: {
        description: "Incontro tra materia preziosa e design. Bridget si illumina dal suo cuore. La lampada da terra in ottone con finitura in oro satinato chiaro lucido e incantevoli inserti in onice tigrato o onice ambra.",
        material: "Ottone con finitura oro satinato chiaro lucido con inserti in onice tigrato o onice ambra.",
        dimensions: "L40 x P14 x H141,5 cm",
        weight: "13 kg",
        light_source: "LED Strip",
        designer: "Simone Fanciullacci",
      },
      bridget_table: {
        description: "Incontro tra materia preziosa e design. Bridget si illumina dal suo cuore. La lampada da scrivania in ottone con finitura in oro satinato chiaro lucido e incantevoli inserti in onice tigrato o onice ambra.",
        material: "Ottone con finitura oro satinato chiaro lucido con inserti in onice tigrato o onice ambra.",
        dimensions: "L26 x P6 x H19 cm",
        weight: "1,3 kg",
        light_source: "LED Strip",
        designer: "Simone Fanciullacci",
      },
      bridget_table_s: {
        description: "Incontro tra materia preziosa e design. Bridget si illumina dal suo cuore. La lampada da tavolo è in ottone con finitura in oro satinato chiaro lucido e incantevoli inserti in onice tigrato o onice ambra.",
        material: "Ottone con finitura oro satinato chiaro lucido con inserti in onice tigrato o onice ambra.",
        dimensions: "L40 x P14 x H60 cm",
        weight: "9,2 kg",
        light_source: "LED Strip",
        designer: "Simone Fanciullacci",
      },
      calipso_table: {
        description: "La luce trova spazio attraverso le sfumature dell'onice grazie all'incontro tra la lavorazione artigianale e una sensibilità moderna. Calipso da tavolo è una lampada LED con base in legno stratificato a poro aperto e cornice in onice ambra.",
        material: "Base in legno stratificato a poro aperto e cornice in onice ambra.",
        dimensions: "L26,5 X P12 x H32 cm",
        weight: "1,5 kg",
        light_source: "LED Strip",
        designer: "G.Luce",
      },
      calipso_wall: {
        description: "La luce trova spazio attraverso le sfumature dell'onice grazie all'incontro tra la lavorazione artigianale e una sensibilità moderna. Calipso da parete consiste in una cornice in onice ambra impreziosita da un'illuminazione a LED. Disponibile in tre dimensioni.",
        material: "Onice ambra",
        dimensions: "L30 x P4 x H40 cm – M: L35 x P4 x H35 cm – L: L25 x P4 x H50 cm",
        weight: "1 kg",
        light_source: "LED Strip",
        designer: "Simone Fanciullacci",
      },
      dome_applique: {
        description: "Rigore geometrico e stile minimale. L'iconico paralume della lampada da parete Dome è disponibile in cristallo smaltato e satinato, nei colori arancio, blu, rosa, bianco.",
        material: "Cristallo smaltato e satinato nei colori: arancio, blu, rosa, bianco",
        dimensions: "Ø 51 x H17 cm",
        weight: "2 Kg",
        light_source: "Scheda LED 11 W | 24 V | 1340 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      dome_floor: {
        description: "Rigore geometrico e stile minimale. L'iconico paralume della lampada da terra Dome è disponibile in cristallo smaltato e satinato, nei colori arancio, blu, rosa, bianco.",
        material: "Metallo e cristallo smaltato e satinato nei colori: arancio, blu, rosa, bianco",
        dimensions: "Ø 51 x H173 cm",
        weight: "9,4 Kg",
        light_source: "Scheda LED 18 W | 24 V | 2240 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      dome_pendant: {
        description: "Rigore geometrico e stile minimale, la leggerezza di forme senza tempo. Un sottile rosone di metallo incornicia l'iconico paralume della lampada a sospensione Dome disponibile in cristallo smaltato e satinato, nei colori arancio, blu, rosa, bianco.",
        material: "Metallo e cristallo smaltato e satinato nei colori: arancio, blu, rosa, bianco",
        dimensions: "Ø 51 x H22 cm",
        weight: "4,2 Kg",
        light_source: "Scheda LED 18 W | 24 V | 2240 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      eike: {
        description: "La matericità del rovere incontra la leggerezza del vetro. Eike è una lampada da tavolo con struttura in rovere massello, parti metalliche in ottone bronzato chiaro e paralume in vetro pirex satinato.",
        material: "Rovere massello e ottone bronzato chiaro. Vetro pixel satinato",
        dimensions: "Ø 47 x H25 cm",
        weight: "7,5 Kg",
        light_source: "Modulo LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "G.Luce",
      },
      empire_floor: {
        description: "Geometria e materia si incontrano in modo nuovo, rivelando un gusto nuovo e una presenza dai riflessi inimitabili. Empire è una lampada da terra in ottone con finitura in oro satinato chiaro lucido o bronzo e paralume in percallina.",
        material: "Ottone con finitura oro satinato chiaro lucido o bronzo. Paralume in percallina.",
        dimensions: "Ø 60 x H186 cm",
        weight: "11,3 Kg",
        light_source: "Modulo LED Sirio 24W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      empire_table: {
        description: "Geometria e materia si incontrano in modo nuovo, rivelando un gusto nuovo e una presenza dai riflessi inimitabili. Empire è una lampada da tavolo in ottone con finitura in oro satinato chiaro lucido o bronzo e paralume in percallina.",
        material: "Ottone con finitura oro satinato chiaro lucido o bronzo. Paralume in percallina.",
        dimensions: "L28 x P28 x H53 cm",
        weight: "7,4 Kg",
        light_source: "Modulo LED Sirio 24W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      foca_l: {
        description: "Foca racchiude in un cono il silenzio e la poesia della luce. Disponibile come lampada da tavolo LED con struttura in plexiglass opale satinato in tre dimensioni: S, M, L.",
        material: "Plexiglass e opale satinato",
        dimensions: "L49 x P24 x H46 cm Ø 40",
        weight: "3,5 kg",
        light_source: "LED Strip",
        designer: "Fém",
      },
      foca_m: {
        description: "Foca racchiude in un cono il silenzio e la poesia della luce. Disponibile come lampada da tavolo LED con struttura in plexiglass opale satinato in tre dimensioni: S, M, L.",
        material: "Plexiglass e opale satinato",
        dimensions: "L39 x P18 x H36 cm Ø 30",
        weight: "2,3 kg",
        light_source: "LED Strip",
        designer: "Fém",
      },
      foca_s: {
        description: "Foca racchiude in un cono il silenzio e la poesia della luce. Disponibile come lampada da tavolo LED con struttura in plexiglass opale satinato in tre dimensioni: S, M, L.",
        material: "Plexiglass e opale satinato",
        dimensions: "L28 x P14 x H24,5 cm Ø 20",
        weight: "1 kg",
        light_source: "LED Strip",
        designer: "Fém",
      },
      gea_legno: {
        description: "Le sfaccettature del legno e la saggezza del maestro ebanista. Gea è una lampada da tavolo in rovere spazzolato naturale o in acero tinto nero lucido arricchita da un paralume conico in percallina.",
        material: "Rovere spazzolato naturale o in acero tinto nero lucido. Paralume in percallina.",
        dimensions: "Ø 39 x H60 cm",
        weight: "2 kg",
        light_source: "Modulo LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      gea_nera: {
        description: "Le sfaccettature del legno e la saggezza del maestro ebanista. Gea è una lampada da tavolo in rovere spazzolato naturale o in acero tinto nero lucido arricchita da un paralume conico in percallina.",
        material: "Rovere spazzolato naturale o in acero tinto nero lucido. Paralume in percallina.",
        dimensions: "Ø 39 x H60 cm",
        weight: "2 kg",
        light_source: "Modulo LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      generator: {
        description: "Le nuove forme della ricercatezza. Generator è una lampada a sospensione con struttura in plexiglass satinato e semisfere in ottone con finitura in bronzo. La staffa è in ottone e i dettagli in bronzo. Disponibile anche nelle versioni da terra e da tavolo.",
        material: "Struttura in plexiglass satinato e semisfere in ottone con finitura in bronzo. Staffa in ottone con dettagli in bronzo.",
        dimensions: "L130 x P15 x H15 cm",
        weight: "-",
        light_source: "LED Strip",
        designer: "Simone Fanciullacci",
      },
      ivy: {
        description: "Il movimento del futuro. Ivy è una lampada da terra con una sorgente luminosa distribuita su due livelli. Disponibile con struttura in acciaio nella finitura oro satinato chiaro e tavolino in marmo Emperador, o bronzo satinato e tavolino in marmo Calacatta oro. Il diffusore in vetro opalino conferisce leggerezza alla struttura.",
        material: "Struttura in acciaio nella finitura oro satinato chiaro e tavolino in marmo Emperador, o bronzo satinato e tavolino in marmo Calacatta oro. Diffurore in vetro opalino.",
        dimensions: "Ø 40 x H182 cm",
        weight: "11,2 kg",
        light_source: "Modulo LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      lucciola_m: {
        description: "La leggiadria della luce. Lucciola è una lampada da tavolo con struttura e parti metalliche in ottone bronzato chiaro e diffusore in vetro pirex satinato. Disponibile in due dimensioni: M, S.",
        material: "Struttura e parti metalliche in ottone bronzato chiaro e diffusore in vetro pirex satinato.",
        dimensions: "Ø 15 x H25 cm",
        weight: "2,4 kg",
        light_source: "Modulo LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "G.Luce",
      },
      lucciola_s: {
        description: "La leggiadria della luce. Lucciola è una lampada da tavolo con struttura e parti metalliche in ottone bronzato chiaro e diffusore in vetro pirex satinato. Disponibile in due dimensioni: M, S.",
        material: "Struttura e parti metalliche in ottone bronzato chiaro e diffusore in vetro pirex satinato.",
        dimensions: "Ø 15 x H18 cm",
        weight: "1,2 kg",
        light_source: "Modulo LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "G.Luce",
      },
      naica: {
        description: "L'incontro tra la luce, il metallo e la pietra. Naica è una lampada da tavolo disponibile con struttura in acciaio nella finitura oro satinato chiaro e diffusore in onice tigrato, o in nickel nero satinato e diffusore in onice ambra.",
        material: "Struttura in acciaio nella finitura oro satinato chiaro e diffusore in onice tigrato, o in nickel nero satinato e diffusore in onice ambra.",
        dimensions: "L21 x P19 x H36,5 cm",
        weight: "4,2 kg",
        light_source: "Modulo LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      norma: {
        description: "Un totem di volumi pieni e vuoti, caratterizzato dal ritmo di elementi geometrici semplici. Norma è una lampada da terra con struttura in acciaio disponibile nelle finiture oro chiaro satinato lucido, nickel, nickel nero. La base in marmo Antracite o Calacatta Oro si confronta con la leggerezza del paralume in doppio tessuto. Disponibile anche nelle versioni a sospensione e da terra.",
        material: "Struttura in acciaio nelle finiture oro chiaro satinato lucido, nickel, nickel nero. Base in marmo Antracite o Calacatta Oro. Paralume in doppio tessuto.",
        dimensions: "Ø 51 x H180 cm",
        weight: "6 kg",
        light_source: "Modulo LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "G.Luce",
      },
      tour_abatjour: {
        description: "Lampada abatjour dalla linea essenziale con finiture di pregio, perfetta per legare in ogni tipo di ambiente. Tour nasce dalla armonia tra il corpo sottile in rovere spazzolato naturale e l'avvolgente paralume in percallina.",
        material: "Rovere spazzolato naturale. Paralume in percallina.",
        dimensions: "L38 x P26 x H48 cm",
        weight: "1,2 kg",
        light_source: "Modulo LED 18 W | 2040 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      tour_floor: {
        description: "Lampada da terra dalla linea essenziale con finiture di pregio, perfetta per legare in ogni tipo di ambiente. Tour nasce dall’armonia tra il corpo sottile in rovere spazzolato naturale e l'avvolgente paralume in percallina.",
        material: "Rovere spazzolato naturale. Paralume in percallina.",
        dimensions: "L75 x P49 x H174 cm",
        weight: "8,6 kg",
        light_source: "Modulo LED 42 W | 4760 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      tour_table: {
        description: "Lampada da tavolo dalla linea essenziale con finiture di pregio, perfetta per legare in ogni tipo di ambiente. Tour nasce dalla armonia tra il corpo sottile in rovere spazzolato naturale e l'avvolgente paralume in percallina.",
        material: "Rovere spazzolato naturale. Paralume in percallina.",
        dimensions: "L50 x P37 x H65 cm",
        weight: "3,2 kg",
        light_source: "Modulo LED 24 W | 2720 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      zena_abatjour: {
        description: "Il trionfo discreto della luce. Zena è una lampada abatjour dalla leggiadra struttura in ottone con finitura in oro satinato chiaro o nickel nero. Il paralume è in vetro opalino.",
        material: "Ottone con finitura in oro satinato chiaro o nickel nero. Paralume in vetro opalino.",
        dimensions: "L24 x P22 x H41 cm",
        weight: "3 kg",
        light_source: "Modulo LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      zena_floor: {
        description: "Il trionfo discreto della luce. Zena è una lampada da terra dalla struttura slanciata in ottone con finitura in oro satinato chiaro o nickel nero. Il paralume è in vetro opalino.",
        material: "Ottone con finitura in oro satinato chiaro o nickel nero. Paralume in vetro opalino.",
        dimensions: "L43 x P43 x H164 cm",
        weight: "12,5 kg",
        light_source: "Modulo LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      zena_table: {
        description: "Il trionfo discreto della luce. Zena è una lampada da tavolo dalla leggiadra struttura in ottone con finitura in oro satinato chiaro o nickel nero. Il paralume è in vetro opalino.",
        material: "Ottone con finitura in oro satinato chiaro o nickel nero. Paralume in vetro opalino.",
        dimensions: "L36 x P34 x H59 cm",
        weight: "7,5 kg",
        light_source: "Modulo LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      }
    },
    related: {
      heading: "Prodotti correlati"
    }
  },
  products: {
    collections: {
      all: "Tutto",
      0: "terra",
      1: "tavolo",
      2: "abatjour",
      3: "sospensione",
      4: "applique",
    }
  },
  custom: {
    intro: {
      heading: "Esplora i diversi livelli di personalizzazione e i servizi di G.Luce",
      1: {
        heading: "ADATTAMENTO DI PRODOTTI DISPONIBILI A CATALOGO",
        text: "Possibilità di personalizzare tutt i prodotti delle Collezioni G.Luce con finiture e sorgenti luminose personalizzate.",
      },
      2: {
        heading: "PRODUZIONE BESPOKE",
        text: "Ogni dispositivo è progettato in base alle esigenze del cliente e in base alle caratteristiche decise durante le fasi di sviluppo del prodotto."
      },
      3: {
        heading: "CONSULENZA E PROGETTAZIONE",
        text: "La nostra rete e i nostri collaboratori partecipano al processo di sviluppo con suggerimenti tecnici e risorse operative."
      },
      4: {
        heading: "SERVIZIO POST-VENDITA",
        text: "Un iter completo creato per poter sempre accompagnare e assistere il cliente e le sue esigenze nel tempo."
      },
    },
    why: {
      heading: "Esplora i diversi livelli di personalizzazione e i servizi di G.Luce:",
      tl: {
        title: "Know How",
        text: "Conoscenza dei materiali, delle tecnologie più innovative e delle strutture di produzione.",
      },
      tr: {
        title: "Networking",
        text: "Partnership sviluppate nel corso di un'esperienza trentennale con aziende specializzate e artigiani del Made in Italy.",
      },
      dl: {
        title: "Experience",
        text: "Alberghi e ristoranti, boutiques, navi crociera, uffici e progetti residenziali.",
      },
      dr: {
        title: "Customer Care",
        text: "Assistenza completa dei nostri clienti in tutte le fasi di progetto fino al rapporto post vendita.",
      },
    },
  },
  network: {
    heading: "Rete",
    text: "G.Luce è il risultato di una storia trentennale fatta di impegno, ricerca e collaborazione con una rete di esperti professionisti. Le migliori tecniche artigianali e la selezione dei materiali più pregiati hanno posato le basi del percorso con cui l’azienda risponde ai bisogni concreti dei propri clienti in modo funzionale ed efficiente.",
    blacksmith: {
      heading: "Il fabbro",
      text: "Ogni punto luce racconta una storia di tecniche impeccabili. La conoscenza dei materiali richiede un esercizio raffinato e durante gli anni sono state sviluppate relazioni e legami profondi con grandi mastri artigiani di tutta Italia e aziende specializzate. Una rete di persone e competenze tecniche supportate dalle risorse e le strutture adatte a produzioni su larga scala.",
    },
    glazier: {
      heading: "Il vetraio",
      text: "La nostra azienda conosce bene il valore degli incontri e negli anni ha voluto creare collaborazioni di progettualità e sviluppo con giovani designer internazionali. In uno scambio che arricchisce entrambe le parti, G.Luce è in grado di monitorare le nuove frontiere del design ed evolvere le proprie creazioni secondo stimoli nuovi e di prospettiva.",
    },
    straw_blower: {
      heading: "L'impagliatore",
      text: "Grazie alle esperienze e alle relazioni maturate dalla partecipazione al mondo delle fiere internazionali, G.Luce ha costruito una rete di interior designer professionisti e studi di architettura con i quali ha collaborato su progetti importanti. ll continuo confronto con diverse realtà ha permesso di creare un gusto senza confini, caratterizzato dalla ricerca dei materiali e declinato secondo una bilanciata eleganza minimale.",
    },
  },
  contact: {
    welcome: "Benvenuto al Servizio Clienti G.Luce.",
    info: "Utilizza questa sezione per inoltrarci le tue richieste di informazioni e assistenza in merito ai nostri prodotti.",
    call_to_action: "Contattaci",
    heading: "Contatti",
    numero_verde: "Numero Verde",
    form: {
      first_name: "Nome*",
      last_name: "Cognome*",
      email: "Email*",
      phone: "Telefono",
      company: "Azienda",
      country: "Paese",
      city: "Città",
      subject: "Oggetto",
      message: "Messaggio",
      contact_mode: {
        heading: "Modalità di contatto preferita",
        email: "Email (campo richiesto)",
        phone: "Telefono (campo richiesto)",
      },
      type: {
        heading: "Tipo di utente",
        professional: "Architetto/Interior designer",
        personal: "Privato",
      },
      privacy: {
        heading: "Privacy",
        text: "Ai sensi della normativa privacy, i dati personali saranno trattati da G.Luce S.p.A nel rispetto della nostra Politica di protezione dei dati personali. In qualsiasi momento, l'utente può esercitare i propri diritti o revocare i consensi eventualmente prestati, anche inviando una mail a info@gluce.i",
      },
      acceptance: {
        heading: "Ho preso visione dell’informativa per il trattamento dati personali di G.Luce S.p.A.",
        activities: "acconsento alle attività di marketing di G.Luce S.p.A.",
        profiling: "acconsento alle attività di profilazione",
        marketing: "acconsento al marketing di G.Luce S.p.A.",
      },
      marked: "I campi contrassegnati con (*) sono obbligatori",
      confirm: "Grazie! Messaggio inviato",
      submit: "Invia",
      policy: "Ai sensi del Codice in materia di protezione dei dati personali (Decreto legislativo 30 giugno 2003, n.196),  adeguato alle disposizioni del Regolamento generale sulla protezione dei dati (UE) 2016/679 (General Data Protection Regulation – “GDPR”) tramite il Decreto legislativo 10 agosto 2018, n.101, tutte le informazioni inviate tramite il presente modulo, saranno tenute strettamente riservate, saranno conservate con la massima sicurezza, non verranno cedute a terzi ed in ogni momento potrete richiedere che vengano modificate o cancellate scrivendo all'attenzione del titolare del trattamento dei dati:",
      authorize: "Autorizzo"
    }
  },
  tech: {
    heading: "SIRIO",
    text: "SIRIO, la sorgente di illuminazione con innovativa tecnologia LED. Un rivoluzionario sistema di illuminazione nato dall’esigenza di poter sostituire in modo agile e semplice le sorgente di luce: le schede intercambiabili uniscono una prestazione altamente efficiente al vantaggio di una installazione rapida.",
    section1: "Ogni singola scheda è fissata alla struttura su due punti e può essere facilmente rimossa e sostituita. La possibilità di sostituire una singola scheda e non l’intero modulo contribuisce in modo significativo alla salvaguardia dell’ambiente con la riduzione di rifiuti elettronici.",
    section2: "G.Luce brevetta un sistema d’illuminazione LED innovativo e tecnologico, comodo e funzionale per poter garantire un ambiente sempre funzionante. Il modulo nasce per soddisfare i parametri di efficienza e qualità delle prestigiose realtà di hôtellerie e navali. La funzione dimmerabile riproduce le caratteristiche di intensità di luce, colore e direzione delle lampadine a incandescenza, avvolgendo ogni ambiente in una atmosfera armoniosa e calda.",
    section3: "Questa filosofia alla base del modulo Sirio viene applicata all’intera gamma G.Luce. Per ogni prodotto della collezione, così come per ogni singolo progetto commissionato, viene studiata la soluzione di illuminazione più adatta a soddisfare quei parametri di semplicità, risparmio energetico e ecologico che ci contraddistinguono.",
  },
  collections: {
    heading: "COLLEZIONI",
    text: "Le nostre storie nutrono di luce ed eleganza ogni ambiente. Le linee classiche incontrano tecnologie sostenibili ed efficienti per rispondere ad ogni esigenza progettuale. Design e materiali ricercati capaci di raccontare l’intimità e nutrire di eleganza l’ambiente che abitano.",
    section1: {
      heading: "Masterpieces",
      text: "I modelli presenti negli ambienti residenziali e le strutture esclusive di tutto il mondo. Design unici realizzati con la più accurata selezione di materiali. Linee che recuperano le cifre stilistiche più famose declinate ora secondo soluzioni funzionali e altitudini minimali.",
    },
    section2: {
      heading: "Classici",
      text: "I pezzi della tradizione caratterizzati da forme senza tempo. Le linee classiche osservate attraverso le lente di una sensibilità moderna diventano soluzioni per un nuovo modo di abitare lo spazio. Elementi di arredo arricchiti da materiali ricercati e illuminati dalla tecnologia Sirio.",
    },
    section3: {
      heading: "Fronteers",
      text: "La naturale attitudine di G.Luce verso la ricerca di nuove soluzioni tecniche. Il rapporto continuo e il confronto con le realtà internazionali incontra approcci originali e designer emergenti dando forma a sorgenti luminose uniche.",
    },
    section5: "Tutte le collezioni di G.Luce possono essere personalizzate: i materiali e le dimensioni possono variare in base alle necessità di richiesta. Il nostro servizio dedicato di consulenza permette anche di trovare nuove soluzioni progettuali e attuare collaborazioni di sviluppo",
  },
  contract: {
    section1: {
      heading: "The project challenge",
      text: "Una rete capillare di fornitori e sub-fornitori esclusivi e specializzati in grado di collaborare attivamente con studi di architettura, general contractor, imprese costruttrici o cantieri navali per sviluppare e realizzare sistemi di illuminazione sia utilizzando prodotti di serie, eventualmente modificati su specifiche esigenze, sia realizzando prodotti tailor-made.",
    },
    section2: "IL SAPERE ARTIGIANALE, LA CULTURA DELLA PRODUZIONE INDUSTRIALE, LA PASSIONE PER LA SPERIMENTAZIONE E L’INNOVAZIONE, LA PROFONDA CONOSCENZA E LA ACCURATA RICERCA DEI MATERIALI, SONO I TRATTI DISTINTIVI DEL SERVIZIO CONTRACT DI G.LUCE",
    section3: "La flessibilità, l’alta professionalità artigianale e l’attenzione ai dettagli, che contraddistinguono G.Luce, si fondono ad una spiccata attitudine a trovare soluzioni uniche, in ottica di continuo rinnovamento.",
  },
  not_found: {
    heading: "Pagina non trovata"
  }
}

export default it;