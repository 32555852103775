const bold = {
  fontWeight: 900,
  fontFamily: "CenturyGothicBold",
}

const boxed = {
  margin: "0 12.5rem !important"
};

const boxed_laptop = {
  margin: "0 8rem !important"
};

const boxed_mobile = {
  margin: "0 !important"
};

const banner = {
  maxWidth: "100vw",
  minHeight: "50rem",
  width: "100%",

  "& > img": {
    width: "100%",
    height: "100%"
  }
}

const banner_laptop = {
  minHeight: "20rem",
}

const banner_mobile = {
  minHeight: "15rem",
}

// styles defined provided layout
const font18 = { fontSize: "0.55rem" };
const font20 = { fontSize: "0.6rem" };
const font22 = { fontSize: "0.75rem" };
const font25 = { fontSize: "0.85rem" };
const font30 = { fontSize: "1rem" };
const font40 = { fontSize: "1.4rem" };
const font50 = { fontSize: "1.8rem" };
const font60 = { fontSize: "2.2rem" };
const spacing10 = { letterSpacing: "0.1rem" };
const spacing50 = { letterSpacing: "0.2rem" };
const spacing80 = { letterSpacing: "0.4rem" };
const spacing100 = { letterSpacing: "0.5rem" };
const line10 = { lineHeight: "0.5rem" };
const line15 = { lineHeight: "0.8rem" };
const line20 = { lineHeight: "1rem" };
const line25 = { lineHeight: "1.2rem" };
const line30 = { lineHeight: "1.5rem" };
const line40 = { lineHeight: "2rem" };
const line50 = { lineHeight: "2.2rem" };
const line60 = { lineHeight: "2.5rem" };
const line80 = { lineHeight: "3rem" };

const subtext = {
  ...font30,
  ...spacing50,
  ...line30
}

const subtext_laptop = {
  ...font25,
  ...spacing50,
  ...line30
}

export {
  bold,
  boxed,
  boxed_laptop,
  boxed_mobile,
  banner,
  banner_laptop,
  banner_mobile,
  font18,
  font20,
  font22,
  font25,
  font30,
  font40,
  font50,
  font60,
  spacing10,
  spacing50,
  spacing80,
  spacing100,
  line10,
  line15,
  line20,
  line25,
  line30,
  line40,
  line50,
  line60,
  line80,
  subtext,
  subtext_laptop
};