import theme from "config/theme";
import React from "react";
// import ReactGA from "react-ga";
import Helmet from "react-helmet";
import { ThemeProvider, useTheme } from "react-jss";
import { Box, Flex } from "rebass";
import { Children } from "templates/interfaces";
import Footer from "./Footer";
import Navbar from "./Navbar";
import "./reset.css";
import Headroom from "react-headroom";
import { createUseStyles } from "react-jss";

// const initializeReactGA = (googleAnalyticsId: string) => {
//   ReactGA.initialize(googleAnalyticsId);
//   if (typeof window !== `undefined`) {
//     ReactGA.pageview(window.location.pathname + window.location.search);
//   }
// };

const useStyles = createUseStyles((theme: any) => ({
  app: {
    background: theme.colors.background,
    minHeight: "100vh",
    overflowX: "hidden",
  },
  container: {
    maxWidth: "100vw",
    height: "100%",
    // margin: "0 15rem !important",
  },
}));

const Layout = ({ children }: Children) => {
  // initializeReactGA(googleAnalyticsId);

  return (
    <ThemeProvider theme={theme}>
      <LayoutComponents children={children} />
    </ThemeProvider>
  );
};

const LayoutComponents = ({ children }: Children) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Box>
      <Helmet>
        <html lang="it" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
        <title>G.Luce - Italian lamps</title>
      </Helmet>

      <Flex
        flexDirection="column"
        justifyContent="center"
        className={classes.app}
      >
        <Headroom>
          <Navbar />
        </Headroom>

        <Box as="main" flex="1" width={1} className={classes.container}>
          {children}
        </Box>

        <Footer />
      </Flex>
    </Box>
  );
};

export default Layout;
