import { I18N } from "i18n";
import { email, street, city, country, phone, fax, numero_verde } from "config/constants";

const ru: I18N = {
  menu: {
    product: "ПРОДУКТЫ",
    collections: "КОЛЛЕКЦИИ",
    tech: "ТЕХНОЛОГИЯ",
    network: "СЕТЬ",
    custom: "ОБЫЧАЙ",
    contract: "ДОГОВОР",
    contact: "КОНТАКТЫ",
  },
  footer: {
    cookie: "ПОЛИТИКА COOKIE",
    privacy: "КОНФИДЕНЦИАЛЬНОСТЬ",
    legal: "ЮРИДИЧЕСКИЕ",
    contact: "УВЕДОМЛЕНИЯ",
    download: "КОНТАКТЫ - СКАЧАТЬ",
  },
  call_to_action: {
    contact_us: "СВЯЖИТЕСЬ С НАМИ"
  },
  home: {
    products: {
      heading: "Высококачественные материалы и инновационные технологии обработки:",
      heading2: "Освещение – успешный результат мастерства и постоянных исследований",
      button: "Откройте для себя наши световые точки",
    },
    areas: {
      discover: "Откройте для себя коллекции",
      custom: "Настройка и дизайн, прототипирование и консультации, контрактные услуги и послепродажное обслуживание.",
      custom_sub: "Откройте для себя услуги G.Luce",
    },
    contacts: {
      gluce: {
        title: "КОНТАКТЫ G.Luce",
        gluce: "G.Luce Srl",
        street,
        city,
        country,
        phone,
        fax,
        email,
        numero_verde
      },
    }
  },
  product: {
    specifications: "Подробности",
    material: "Материал:",
    dimensions: "Измерение:",
    weight: "Масса:",
    light_source: "Источник света:",
    designer: "Дизайнер",
    datasheet: "Технический паспорт",
    info: {
      agua_applique: {
        description: "Луч света. Agua - это настенный светильник цилиндрической формы, генерирующий двойной световой луч с обоих концов. Структура из бронзовой латуни и корпус из зеленого мрамора Guatemala придают ему элегантную и в то же время простую форму.",
        material: "Отделка из бронзовой латуни и зеленого гватемальского мрамора",
        dimensions: "Ø6 x D11 x H36 cm",
        weight: "2,8 Kg",
        light_source: "LED 4 W | 24 V | 425 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      agua_pendant: {
        description: "Луч света. Подвесной светильник с 1 лампой, структура из бронзовой латуни и корпус из зеленого мрамора Guatemala. Светильник, рожденный с изысканным сочетанием драгоценных материалов.",
        material: "Отделка из бронзовой латуни и зеленого гватемальского мрамора",
        dimensions: "Ø 6 x H32 cm – Максимальная длина: 135 cm – Размеры розетка: Ø 14 x H2,5 cm",
        weight: "1,7 Kg",
        light_source: "LED 4 W | 24 V | 425 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      agua_pendant_3: {
        description: "Луч света. Подвесной светильник с 3 лампами, структура из бронзовой латуни и корпус из зеленого мрамора Guatemala. Светильник, рожденный с изысканным сочетанием драгоценных материалов.",
        material: "Отделка из бронзовой латуни и зеленого гватемальского мрамора",
        dimensions: "Ø 6 x H32 cm – maximum length: 135 cm - ceiling bracket: W60 x D6 x H3,5 cm",
        weight: "5,5 Kg",
        light_source: "LED 12 W | 24 V | 1275 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      aura_floor: {
        description: "Элегантный торшер с минималистичным дизайном. Структура из латуни, украшенная ярким светлым атласным золотом, медью или бронзой, сочетается на своем пике с декорированным стеклянным абажуром, определяющим идеальный баланс между современностью и традициями.",
        material: "Оправа из латуни с матовой отделкой под светлое золото, медь или бронзу. Абажур из декорированного стекла",
        dimensions: "Ø 33 x H158 cm",
        weight: "15,3 Kg",
        light_source: "LED 36 W | 3900 lmn | 3000 K",
        designer: "G.Luce",
      },
      aura_table: {
        description: "Элегантная настольная лампа с минималистичным дизайном. Латунная структура, обогащенная глянцевым светлым сатинированным золотом, медью или бронзой, сочетается с декорированным стеклянным абажуром, определяя идеальный баланс между современностью и традициями.",
        material: "Оправа из латуни с матовой отделкой под светлое золото, медь или бронзу. Абажур из декорированного стекла",
        dimensions: "Ø 33 x H50 cm",
        weight: "7,1 Kg",
        light_source: "LED module 36 W | 3900 lmn | 3000 K",
        designer: "G.Luce",
      },
      balestra: {
        description: "Напряжение и равновесие достигаются в гармонии скульптурной красоты. Balestra - это торшер с основанием из мрамора и латуни с глянцевой светлой матовой золотой отделкой. Абажур может быть выполнен из сатинированного стекла (опалового или цветного) или алюминия в различных вариантах отделки.",
        material: "Основание из мрамора и латуни, отделка под матовое золото. Абажур может быть из опалового стекла, цветного сатина или алюминия.",
        dimensions: "W12 x D58 x H203 cm",
        weight: "23,8 Kg",
        light_source: "LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      bridget_applique: {
        description: "Сочетание драгоценного материала и дизайна. Bridget светится от всего сердца. Бра из латуни с глянцевой отделкой под светлое сатинированное золото и очаровательными вставками из тигрового или янтарного оникса.",
        material: "Латунь с отделкой под светлое матовое золото и вставками из тигрового или янтарного оникса.",
        dimensions: "W7 x D18,5 x H26 cm",
        weight: "1,3 Kg",
        light_source: "LED Strip",
        designer: "G.Luce",
      },
      bridget_floor: {
        description: "Encounter between precious material and design. Bridget lights up from her heart. The floor lamp is made of brass in a light satin gold finish and enchanting inserts in brindle or amber onyx.",
        material: "Латунь с отделкой под светлое матовое золото и вставками из тигрового или янтарного оникса.",
        dimensions: "W40 x D14 x H141,5 cm",
        weight: "13 Kg",
        light_source: "LED Strip",
        designer: "Simone Fanciullacci",
      },
      bridget_table: {
        description: "Сочетание драгоценного материала и дизайна. Bridget светится от всего сердца. Бра из латуни с глянцевой отделкой под светлое сатинированное золото и очаровательными вставками из тигрового или янтарного оникса.",
        material: "Латунь с отделкой под светлое матовое золото и вставками из тигрового или янтарного оникса.",
        dimensions: "W26 x D6 x H19 cm",
        weight: "1,3 Kg",
        light_source: "LED Strip",
        designer: "Simone Fanciullacci",
      },
      bridget_table_s: {
        description: "Сочетание драгоценного материала и дизайна. Bridget светится от всего сердца. Бра из латуни с глянцевой отделкой под светлое сатинированное золото и очаровательными вставками из тигрового или янтарного оникса.",
        material: "Латунь с отделкой под светлое матовое золото и вставками из тигрового или янтарного оникса.",
        dimensions: "W40 x D14 x H60 cm",
        weight: "9,2 Kg",
        light_source: "LED Strip",
        designer: "Simone Fanciullacci",
      },
      calipso_table: {
        description: "Свет находит пространство в оттенках оникса благодаря сочетанию мастерства и современной чувствительности. Настольная лампа Calipso - это светодиодный светильник с многослойным деревянным основанием с открытыми порами и рамой из янтарного оникса.",
        material: "Основание из ламинированного дерева с открытыми порами и кромки из янтарного оникса",
        dimensions: "W26,5 X D12 x H32 cm",
        weight: "1,5 Kg",
        light_source: "LED Strip",
        designer: "G.Luce",
      },
      calipso_wall: {
        description: "Свет находит пространство в оттенках оникса благодаря сочетанию мастерства и современной чувствительности. Настенная лампа Calipso состоит из рамы из янтарного оникса, украшенной светодиодной подсветкой. Доступна в 3-х размерах.",
        material: "Основание из ламинированного дерева с открытыми порами и кромки из янтарного оникса",
        dimensions: "S: W30 x D4 x H40 cm – M: W35 x D4 x H35 cm – L: W25 x D4 x H50 cm",
        weight: "1 Kg",
        light_source: "LED Strip",
        designer: "Simone Fanciullacci",
      },
      dome_applique: {
        description: "Геометрическая строгость и минималистичный стиль. Культовый абажур настенного светильника Dome доступен из эмалированного и сатинированного стекла в оранжевом, синем, розовом, белом цветах.",
        material: "Металлическая конструкция и матовое стекло (оранжевый, синий, розовый, белый)",
        dimensions: "Ø 51 x H17 cm",
        weight: "2 Kg",
        light_source: "LED board 11 W | 24 V | 1340 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      dome_floor: {
        description: "Геометрическая строгость и минималистичный стиль. Культовый абажур торшера Dome доступен в глазурованном и сатиновом кристалле, в оранжевом, синем, розовом и белом цветах.",
        material: "Металлическая конструкция и матовое стекло (оранжевый, синий, розовый, белый)",
        dimensions: "Ø 51 x H173 cm",
        weight: "9,4 Kg",
        light_source: "LED board 18 W | 24 V | 2240 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      dome_pendant: {
        description: "Геометрическая строгость и минималистичный стиль, легкость вневременных форм. Тонкая металлическая рамка обрамляет культовый абажур подвесной лампы Dome, доступный в эмалированном и сатиновом стекле оранжевого, синего, розового и белого цветов.",
        material: "Металлическая конструкция и матовое стекло (оранжевый, синий, розовый, белый)",
        dimensions: "Ø 51 x H22 cm",
        weight: "4,2 Kg",
        light_source: "LED board 18 W | 24 V | 2240 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      eike: {
        description: "Материальность дуба сочетается с легкостью стекла. Eike - это настольная лампа с массивной структурой дуба, металлическими частями из легкой бронзовой латуни и абажуром из сатинированного стекла pirex.",
        material: "Структура из массива дуба, металлические детали из светлой бронзовой латуни и абажур из сатинированного стекла Pyrex. ",
        dimensions: "Ø 47 x H25 cm",
        weight: "7,5 Kg",
        light_source: "LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "G.Luce",
      },
      empire_floor: {
        description: "Геометрия и материя встречаются в новом виде, открывая новое восприятие. Empire - это торшер из латуни с отделкой под глянцевое золото или бронзу и перкаллиновый абажур.",
        material: "Латунь с отделкой под светлое сатинированное золото или бронзу. Абажур из перкаллинового стекла.",
        dimensions: "Ø 60 x H186 cm",
        weight: "11,3 Kg",
        light_source: "LED module Sirio 24W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      empire_table: {
        description: "Геометрия и материя встречаются в новом виде, открывая новое восприятие. Ампир - это настольная лампа из латуни с глянцевым покрытием из светлого сатинированного золота или бронзы и перкаллиновым абажуром.",
        material: "Латунь с отделкой под светлое сатинированное золото или бронзу. Абажур из перкаллинового стекла.",
        dimensions: "W28 x D28 x H53 cm",
        weight: "7,4 Kg",
        light_source: "LED module Sirio 24W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      foca_l: {
        description: "Foca заключает в конус тишину и поэзию света. Доступна в виде светодиодной настольной лампы со структурой из сатинированного опалового оргстекла трех размеров: S, M, L.",
        material: "Структура опалового сатинированного оргстекла",
        dimensions: "W49 x D24 x H46 cm Ø 40",
        weight: "3,5 Kg",
        light_source: "LED Strip",
        designer: "Fém",
      },
      foca_m: {
        description: "Foca заключает в конус тишину и поэзию света. Доступна в виде светодиодной настольной лампы со структурой из сатинированного опалового оргстекла трех размеров: S, M, L.",
        material: "Структура опалового сатинированного оргстекла",
        dimensions: "W39 x D18 x H36 cm Ø 30",
        weight: "2,3 Kg",
        light_source: "LED Strip",
        designer: "Fém",
      },
      foca_s: {
        description: "Foca заключает в конус тишину и поэзию света. Доступна в виде светодиодной настольной лампы со структурой из сатинированного опалового оргстекла трех размеров: S, M, L.",
        material: "Структура опалового сатинированного оргстекла",
        dimensions: "W28 x D14 x H24,5 cm Ø 20",
        weight: "1 Kg",
        light_source: "LED Strip",
        designer: "Fém",
      },
      gea_legno: {
        description: "Грани дерева и мудрость мастера-краснодеревщика. Gea - настольная лампа из натурального или матового дуба или из глянцевого тонированного клена черного цвета, обогащенного перкаллиновым абажуром конической формы *.",
        material: "Дуб натуральный матовый или черный глянцевый клен. Абажур из перкалина.",
        dimensions: "Ø 39 x H60 cm",
        weight: "2 Kg",
        light_source: "LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      gea_nera: {
        description: "Грани дерева и мудрость мастера-краснодеревщика. Gea - настольная лампа из натурального или матового дуба или из глянцевого тонированного клена черного цвета, обогащенного перкаллиновым абажуром конической формы *.",
        material: "Дуб натуральный матовый или черный глянцевый клен. Абажур из перкалина.",
        dimensions: "Ø 39 x H60 cm",
        weight: "2 Kg",
        light_source: "LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      generator: {
        description: "Новые формы изысканности. Generator - подвесной светильник со структурой из сатинированного оргстекла. и латунные полусферы с бронзовой отделкой. Кронштейн из латуни, а детали из бронзы. Также доступны в настенном, напольном и настольном вариантах.",
        material: "Структура из атласного оргстекла и полусфера из латуни с отделкой под бронзу. Потолочный кронштейн из латуни и бронзы.",
        dimensions: "W130 x D15 x H15 cm",
        weight: "-",
        light_source: "LED Strip",
        designer: "Simone Fanciullacci",
      },
      ivy: {
        description: "Движение будущего. Ivy - это торшер с источником света, распределенным на двух уровнях. Доступен со стальной структурой с отделкой из светлого сатинированного золота и журнальным столиком из мрамора Emperador или из сатинированной бронзы и журнальным столиком из золотого мрамора Calacatta. Рассеиватель из опалового стекла придает легкость конструкции.",
        material: "Стальная конструкция с отделкой под светлое сатинированное золото с мрамором Emperador или стальная конструкция с отделкой под сатинированную бронзу с отделкой из золотого мрамора Calacatta.",
        dimensions: "Ø 40 x H182 cm",
        weight: "11,2 Kg",
        light_source: "LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      lucciola_m: {
        description: "Изящность света. Lucciola - настольная лампа со структурой и металлическими деталями. из светлой бронзированной латуни и диффузора из стекла pirex атлас. Доступен в двух размерах: M, S.",
        material: "Каркас и металлические детали из латуни цвета светлой бронзы, диффузор из сатинированного стекла пирекс.",
        dimensions: "Ø 15 x H25 cm",
        weight: "2,4 Kg",
        light_source: "LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "G.Luce",
      },
      lucciola_s: {
        description: "Изящность света. Lucciola - настольная лампа со структурой и металлическими деталями. из светлой бронзированной латуни и диффузора из стекла pirex атлас. Доступен в двух размерах: M, S.",
        material: "Каркас и металлические детали из латуни цвета светлой бронзы, диффузор из сатинированного стекла пирекс.",
        dimensions: "Ø 15 x H18 cm",
        weight: "1,2 Kg",
        light_source: "LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "G.Luce",
      },
      naica: {
        description: "Встреча света, металла и камня. Naica - настольная лампа, доступная со стальной структурой, отделкой из светлого сатинированного золота и абажуром из тигрового оникса или из черного сатинированного никеля и диффузором из янтарного оникса.",
        material: "Стальная конструкция с отделкой из светлого сатинированного золота и диффузором из тигрового оникса или отделкой из черного никеля и диффузором из янтарного оникса",
        dimensions: "W21 x D19 x H36,5 cm",
        weight: "4,2 Kg",
        light_source: "LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      norma: {
        description: "Тотем полных и пустых объемов, характеризующийся ритмом простых геометрических элементов. Norma - это торшер со стальной конструкцией, доступный в отделке из светлого сатинированного золота, никеля или черного никеля. Основание из антрацита или мрамора Calacatta Oro контрастирует с легкостью двойного тканевого абажура. Также доступны в подвесном и напольном вариантах.",
        material: "Стальная конструкция доступна с отделкой под светлое сатинированное золото, никель или черный никель. Основание из антрацитового мрамора или Calacatta Oro. Абажур выполнен из двух тканей.",
        dimensions: "Ø 51 x H180 cm",
        weight: "6 Kg",
        light_source: "LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "G.Luce",
      },
      tour_abatjour: {
        description: "Лампа abatjour с простой линией и изысканной отделкой, идеально подходящая для любого помещения. Tour рожден из гармонии между тонким корпусом из натурального матового дуба и обволакивающим перкаллиновым абажуром.",
        material: "Дуб натуральный брашированный. Абажур из перкаллинового стекла.",
        dimensions: "W38 x D26 x H48 cm",
        weight: "1,2 Kg",
        light_source: "LED module 18 W | 2040 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      tour_floor: {
        description: "Торшер с простой линией и изысканной отделкой идеально подходит для любого помещения. Tour рожден из гармонии между тонким корпусом из натурального матового дуба и обволакивающим перкаллиновым абажуром.",
        material: "Дуб натуральный брашированный. Абажур из перкаллинового стекла.",
        dimensions: "W75 x D49 x H174 cm",
        weight: "8,6 Kg",
        light_source: "LED module 42 W | 4760 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      tour_table: {
        description: "Настольная лампа с простой линией и изысканной отделкой, идеально подходящая для любого помещения. Tour рожден из гармонии между тонким корпусом из натурального матового дуба и обволакивающим перкаллиновым абажуром.",
        material: "Дуб натуральный брашированный. Абажур из перкаллинового стекла.",
        dimensions: "W50 x D37 x H65 cm",
        weight: "3,2 Kg",
        light_source: "LED module 24 W | 2720 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      zena_abatjour: {
        description: "Триумфальная сдержанность света. Zena - это абажур с изящной структурой из латуни с отделкой из светлого сатинированного золота или черного никеля. Абажур из опалового стекла.",
        material: "Структура из латуни с отделкой из светлого сатинированного золота или черного никеля. Абажур из опалового стекла.",
        dimensions: "W24 x D22 x H41 cm",
        weight: "3 Kg",
        light_source: "LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      zena_floor: {
        description: "Триумфальная сдержанность света. Zena - это торшер с тонкой структурой из латуни с отделкой из светлого сатинированного золота или черного никеля. Абажур из опалового стекла.",
        material: "Структура из латуни с отделкой из светлого сатинированного золота или черного никеля. Абажур из опалового стекла.",
        dimensions: "W43 x D43 x H164 cm",
        weight: "12,5 Kg",
        light_source: "LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      zena_table: {
        description: "Триумфальная сдержанность света. Zena - это настольная лампа с изящной латунной структурой с отделкой из светлого сатинированного золота или черного никеля. Абажур из опалового стекла.",
        material: "Структура из латуни с отделкой из светлого сатинированного золота или черного никеля. Абажур из опалового стекла.",
        dimensions: "W36 x D34 x H59 cm",
        weight: "7,5 Kg",
        light_source: "LED Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      }
    },
    related: {
      heading: "Похожие продукты"
    }
  },
  products: {
    collections: {
      all: "all",
      0: "ПОЛ",
      1: "ТАБЛИЦА",
      2: "ФОНАРЬ",
      3: "ЛЮСТРЫ",
      4: "ПРИМЕНЕНИЕ",
    }
  },
  custom: {
    intro: {
      heading: "Изучите различные уровни индивидуализации и услуги G.Luce",
      1: {
        heading: "АДАПТАЦИЯ ПРОДУКТОВ, ДОСТУПНЫХ В КАТАЛОГЕ",
        text: "Возможность персонализировать все продукты коллекций G.Luce с индивидуальной отделкой и источниками света.",
      },
      2: {
        heading: "ПРОИЗВОДСТВО ПО ИНДИВИДУАЛЬНОМУ ЗАКАЗУ",
        text: "Каждое устройство изготовляется в соответствии с потребностями клиента и в соответствии с характеристиками, определенными на этапах разработки продукта."
      },
      3: {
        heading: "КОНСУЛЬТАЦИИ И ПРОЕКТИРОВАНИЕ",
        text: "Наша сеть и наши сотрудники участвуют в процессе разработки, предлагая технические предложения и оперативные ресурсы."
      },
      4: {
        heading: "ОБСЛУЖИВАНИЕ ПОСЛЕ ПОКУПКИ",
        text: "Полный процесс, созданный для постоянного сопровождения и поддержки клиента и его потребностей в течение долгого времени."
      },
    },
    why: {
      heading: "Изучите различные уровни индивидуализации и услуги G.Luce",
      tl: {
        title: "Know How",
        text: "Знание материалов, новейших технологий и производственных структур.",
      },
      tr: {
        title: "Сетевое партнерство",
        text: "развитое на протяжении тридцати лет со специализированными компаниями и ремесленниками Made in Italy",
      },
      dl: {
        title: "Опыт",
        text: "отели и рестораны, бутики, круизные лайнеры, офисы и жилые проекты.",
      },
      dr: {
        title: "Обслуживание клиентов",
        text: "Полная поддержка наших клиентов на всех этапах проекта, вплоть до обслуживания после покупки.",
      },
    },
  },
  network: {
    heading: "Rete",
    text: "G.Luce - это результат тридцатилетней истории исследований и сотрудничества с сетью профессиональных экспертов. Лучшие методы изготовления и выбор лучших материалов заложили основу для того, чтобы компания функционально и эффективно реагировала на конкретные потребности своих клиентов.",
    blacksmith: {
      heading: "Il fabbro",
      text: "Каждый световой элемент рассказывает историю безупречной техники. Знание материалов требует отточенных навыков, и за эти годы были установлены тесные отношения и связи с великими мастерами со всей Италии и специализированными компаниями. Сеть людей и технических навыков, поддерживаемая ресурсами и структурами, подходящими для крупномасштабного производства.",
    },
    glazier: {
      heading: "Il vetraio",
      text: "Наша компания хорошо понимает ценность встреч и на протяжении многих лет налаживает сотрудничество в области планирования и развития с молодыми международными дизайнерами. В данном обмене, который обогащает обе стороны, G.Luce может следить за новыми рубежами дизайна и развивать свои творения в соответствии с новыми стимулами и перспективами.",
    },
    straw_blower: {
      heading: "L'impagliatore",
      text: "Благодаря опыту и связям, полученным в результате участия в международных выставках, G.Luce создал сеть профессиональных дизайнеров интерьеров и архитектурных бюро, с которыми он сотрудничал над важными проектами. Непрерывное противостояние различным реалиям позволило создать безграничный вкус, характеризующийся исследованием материалов и в балансе с элегантностью минимализма.",
    },
  },
  contact: {
    welcome: "Добро пожаловать в отдел обслуживания клиентов G. Luce.",
    info: "Вы можете использовать эту сессию, чтобы отправлять нам свои запросы о наших",
    call_to_action: "СВЯЖИТЕСЬ С НАМИ",
    heading: "КОНТАКТЫ",
    numero_verde: "Горячая линия",
    form: {
      first_name: "Имя*",
      last_name: "Фамилия*",
      company: "Компания",
      country: "Paese",
      city: "Città",
      subject: "Предмет",
      message: "Сообщение",
      contact_mode: {
        heading: "Modalità di contatto preferita",
        email: "Email (campo richiesto)",
        phone: "Telefono (campo richiesto)",
      },
      email: "Электронное письмо*",
      phone: "Телефон",
      type: {
        heading: "Tipo di utente",
        professional: "Architetto/Interior designer",
        personal: "Privato",
      },
      privacy: {
        heading: "Privacy",
        text: "Ai sensi della normativa privacy, i dati personali saranno trattati da G.Luce S.p.A nel rispetto della nostra Politica di protezione dei dati personali. In qualsiasi momento, l'utente può esercitare i propri diritti o revocare i consensi eventualmente prestati, anche inviando una mail a info@gluce.i",
      },
      acceptance: {
        heading: "Ho preso visione dell’informativa per il trattamento dati personali di G.Luce S.p.A.",
        activities: "acconsento alle attività di marketing di G.Luce S.p.A.",
        profiling: "acconsento alle attività di profilazione",
        marketing: "acconsento al marketing di G.Luce S.p.A.",
      },
      marked: "I campi contrassegnati con (*) sono obbligatori",
      confirm: "Thanks! Message submitted",
      submit: "Представлять",
      policy: "Pursuant to Code regarding the protection of personal data (Legislative Decree 30 June 2003, no.196), adapted to the provisions of the General Data Protection Regulation “GDPR” (EU) 2016/679 through Legislative Decree 10 August 2018, no.101, all information sent through this form will be kept strictly confidential, with the utmost security, will not be transferred to third parties and at any time you can request to modify or delete them by writing for attention of the data controller:",
      authorize: "Authorize"
    }
  },
  tech: {
    heading: "SIRIO",
    text: "SIRIO, источник освещения с инновационной светодиодной технологией. Революционная система освещения, рожденная из-за необходимости быстрой и простой замены источников света: сменные панели сочетают в себе высокую эффективность с преимуществом быстрой установки.",
    section1: "Каждая отдельная панель крепится к конструкции в двух точках и может быть легко снята и заменена. Возможность замены одной части, а не всего модуля, значительно способствует защите окружающей среды за счет сокращения электронных отходов.",
    section2: "G.Luce патентует инновационную и технологичную систему светодиодного освещения, удобную и функциональную.",
    section3: "Эта философия, лежащая в основе модуля Sirio, применяется ко всей линейке G.Luce. Для каждого продукта в коллекции, а также для каждого отдельного заказанного проекта изучается наиболее подходящее световое решение, отвечающее тем параметрам простоты, энергосбережения и экологичности, которые отличает нас.",
  },
  collections: {
    heading: "КОЛЛЕКЦИИ",
    text: "Наши истории наполняют каждое помещение светом и элегантностью. Классические линии сочетаются с экологичными и эффективными технологиями для удовлетворения любых требований к дизайну. Изысканный дизайн и материалы, способные передать интимную атмосферу и элегантно обогатить помещение.",
    section1: {
      heading: "Коллекция Masterpieces",
      text: "Модели представлены в жилых помещениях и эксклюзивных строениях по всему миру. Уникальный дизайн, выполненный с применением тщательного отбора материалов. Линии, воссоздающие самые известные стилистические образы, теперь уменьшились в соответствии с функциональными решениями и минимальными установками. Откройте для себя коллекцию Masterpieces",
    },
    section2: {
      heading: "Коллекция Classici",
      text: "Традиционные экземпляры, отличающиеся вневременными формами. Классические линии, которые возможно расмотреть через призму современной чувствительности, становятся решениями для нового образа жизни. Элементы интерьера, обогащенные изысканными материалами и освещенные технологией Sirio. Откройте для себя коллекцию Classici от G.LUCE",
    },
    section3: {
      heading: "Коллекция Fronteers",
      text: "Естественное отношение G.Luce к поиску новых технических решений. Постоянная связь и сравнение с международными реалиями в симбиозе c оригинальным подходoм и выдающимися дизайнерами, придают форму уникальным источникам света. Откройте для себя коллекцию Frontiere   нашего дизайна.",
    },
    section5: "Все коллекции G.Luce можно изготовить на заказ: материалы и размеры могут варьироваться в зависимости от требований запроса. Наши специализированные консультационные услуги также позволяют вам найти новые дизайнерские решения и реализовать совместные разработки.",
  },
  contract: {
    section1: {
      heading: "The project challenge",
      text: "Широко распространенная сеть эксклюзивных и специализированных поставщиков и субпоставщиков, способных активно сотрудничать со студиями архитектуры, с подрядчиками, строительными компаниями для разработки и внедрения систем освещения с использованием стандартных продуктов, возможно, модифицированных в соответствии с конкретными потребностями, как путем создания индивидуальных изделия ручной работы.",
    },
    section2: "Знания ремесленников, культура промышленного производства, страсть к экспериментам и инновациям, глубокие знания и тщательное исследование материалов - отличительные черты G.Luce",
    section3: "Гибкость, высокое мастерство, профессионализм и внимание к деталям, которые отличает G.Luce, сочетаются со стойким отношением к поиску уникальных решений с целью постоянного обновления.",
  },
  not_found: {
    heading: "Pagina non trovata"
  }
}

export default ru;