import { ReactComponent as Logo } from "assets/logo/GLuce_logo_bianco.svg";
import { bold, font25, spacing10 } from "config/styles";
import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useNavigate } from "react-router-dom";
import { Box, Flex, Text } from "rebass";
import SocialIcons from "./SocialIcons";

const useStyles = createUseStyles((theme: any) => ({
  root: {
    background: theme.colors.footer,
    color: theme.colors.text,
    textAlign: "center",
    zIndex: 100,
  },
  logo: {
    cursor: "pointer",
    maxHeight: "3rem",
    maxWidth: "4rem",
  },
  menu: {
    "& > a": {
      textTransform: "uppercase",
      fontSize: "0.75rem",
      color: theme.colors.text,
      "&:hover": {
        textDecoration: "none",
        ...bold,
      },

      "&:not(:first-child)": {
        "&:before": {
          content: "'|'",
          margin: "0 1rem",
          color: theme.colors.text,
        },
      },
    },
  },
  company: {
    ...font25,
    ...spacing10,
  },
}));

function Footer() {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bg="backgroundFooter"
      py={[4]}
      px={[2, 1]}
      className={classes.root}
    >
      <Logo className={classes.logo} onClick={() => navigate("/")} />

      <Box my="1.5rem">
        <Text className={classes.company}>
          © G.LUCE All rights reserved - G. Luce Srl. viale Brianza 20, 20823
          Lentate sul Seveso (MB) - VAT N° 08178020965 - info@gluce.it
        </Text>
      </Box>

      <SocialIcons white />
    </Flex>
  );
}

export default React.memo(Footer);
