import { I18N } from "i18n";
import { email, street, city, country, phone, fax, numero_verde } from "config/constants";

const en: I18N = {
  menu: {
    product: "Products",
    collections: "Collections",
    tech: "Tech",
    network: "Network",
    custom: "Custom",
    contract: "Contract",
    contact: "Contact",
  },
  footer: {
    cookie: "Cookie Policy",
    privacy: "Privacy Policy",
    legal: "Legal Notes",
    contact: "Contact",
    download: "Download",
  },
  call_to_action: {
    contact_us: "Contact Us"
  },
  home: {
    products: {
      heading: "High quality materials and innovative processing techniques:",
      heading2: "lights as the successful outcome of craftsmanship and constant research",
      button: "Discover our Lights",
    },
    areas: {
      discover: "Discover the Collections",
      custom: "CUSTOMIZATION AND DESIGN, PROTOTYPING AND CONSULTING, CONTRACT SERVICES AND POST-SALE ASSISTANCE",
      custom_sub: "Discover G.Luce Services"
    },
    contacts: {
      gluce: {
        title: "G.Luce Contacts",
        gluce: "G.Luce Srl",
        street,
        city,
        country,
        phone,
        fax,
        email,
        numero_verde,
      }
    }
  },
  product: {
    specifications: "Specifications",
    material: "Material:",
    dimensions: "Dimensions:",
    weight: "Weight:",
    light_source: "Light Source:",
    designer: "Designed by",
    datasheet: "Datasheet",
    info: {
      agua_applique: {
        description: "Column of light. Agua is a cylindrical wall sconce which generates a double up-down narrow light beam. The structure in a bronzed brass finish and the body made of green Guatemala marble shape the lamp with an elegant simplicity.",
        material: "Bronzed brass finish and green Guatemala marble",
        dimensions: "Ø6 x D11 x H36 cm",
        weight: "2,8 Kg",
        light_source: "LED board 4 W | 24 V | 425 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      agua_pendant: {
        description: "Column of light. A single pendant with the structure in a bronzed brass finish and the body made of green Guatemala marble. A light with a subtle beauty born from the sophisticated encounter of precious materials.",
        material: "Bronzed brass finish and green Guatemala marble",
        dimensions: "Ø 6 x H32 cm – maximum length: 135 cm - ceiling bracket: Ø 14 x H2,5 cm",
        weight: "1,7 Kg",
        light_source: "LED board 4 W | 24 V | 425 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      agua_pendant_3: {
        description: "Column of light. A three-lights pendant with the structure in a bronzed brass finish and the body made of green Guatemala marble. A light with a subtle beauty born from the sophisticated encounter of precious materials.",
        material: "Bronzed brass finish and green Guatemala marble",
        dimensions: "Ø 6 x H32 cm – maximum length: 135 cm - ceiling bracket: W60 x D6 x H3,5 cm",
        weight: "5,5 Kg",
        light_source: "LED board 12 W | 24 V | 1275 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      aura_floor: {
        description: "Elegant floor lamp with a minimal design. The brass structure, enriched by a choice of satin light gold, copper or bronze finish, meets the decorative glass lampshade defining a perfect balance between modernity and tradition.",
        material: "Brass structure enriched by satin light gold, copper or bronze finish. Decorative glass lampshade",
        dimensions: "Ø 33 x H158 cm",
        weight: "15,3 Kg",
        light_source: "LED module 36 W | 3900 lmn | 3000 K",
        designer: "G.Luce",
      },
      aura_table: {
        description: "Elegant table lamp with a minimal design. The brass structure, enriched by a choice of satin light gold, copper or bronze finish, meets the decorative glass lampshade defining a perfect balance between modernity and tradition.",
        material: "Brass structure enriched by satin light gold, copper or bronze finish. Decorative glass lampshade",
        dimensions: "Ø 33 x H50 cm",
        weight: "7,1 Kg",
        light_source: "LED module 36 W | 3900 lmn | 3000 K",
        designer: "G.Luce",
      },
      balestra: {
        description: "Tension and balance achieved in the harmony of a sculptural beauty. Balestra is a floor lamp with a marble and brass base, and a satin light gold finish. The lampshade can be made either of opal or coloroured satin glass or of aluminum in a selection of different finishes.",
        material: "Marble and brass base, and a satin light gold finish. The lampshade can be made either of opal or coloroured satin glass or of aluminum.",
        dimensions: "W12 x D58 x H203 cm",
        weight: "23,8 Kg",
        light_source: "LED module Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      bridget_applique: {
        description: "Encounter between precious material and design. Bridget lights up from her heart. The wall sconce is made of brass in a light satin gold finish and enchanting inserts in brindle or amber onyx.",
        material: "Brass in a light satin gold finish and inserts in brindle or amber onyx.",
        dimensions: "W7 x D18,5 x H26 cm",
        weight: "1,3 Kg",
        light_source: "LED Strip",
        designer: "G.Luce",
      },
      bridget_floor: {
        description: "Encounter between precious material and design. Bridget lights up from her heart. The floor lamp is made of brass in a light satin gold finish and enchanting inserts in brindle or amber onyx.",
        material: "Brass in a light satin gold finish and inserts in brindle or amber onyx.",
        dimensions: "W40 x D14 x H141,5 cm",
        weight: "13 Kg",
        light_source: "LED Strip",
        designer: "Simone Fanciullacci",
      },
      bridget_table: {
        description: "Encounter between precious material and design. Bridget lights up from her heart. The wall sconce is made of brass in a light satin gold finish and enchanting inserts in brindle or amber onyx.",
        material: "Brass in a light satin gold finish and inserts in brindle or amber onyx.",
        dimensions: "W26 x D6 x H19 cm",
        weight: "1,3 Kg",
        light_source: "LED Strip",
        designer: "Simone Fanciullacci",
      },
      bridget_table_s: {
        description: "Encounter between precious material and design. Bridget lights up from her heart. The wall sconce is made of brass in a light satin gold finish and enchanting inserts in brindle or amber onyx.",
        material: "Brass in a light satin gold finish and inserts in brindle or amber onyx.",
        dimensions: "W40 x D14 x H60 cm",
        weight: "9,2 Kg",
        light_source: "LED Strip",
        designer: "Simone Fanciullacci",
      },
      calipso_table: {
        description: "Light finds space through the shades of onyx thanks to the encounter between craftsmanship and a modern sensibility. Calipso table is a LED lamp with a base in layered open pore wood and amber onyx frame.",
        material: "Base in layered open pore wood and amber onyx frame",
        dimensions: "W26,5 X D12 x H32 cm",
        weight: "1,5 Kg",
        light_source: "LED Strip",
        designer: "G.Luce",
      },
      calipso_wall: {
        description: "Light finds space through the shades of onyx thanks to the encounter between craftsmanship and a modern sensibility. Calipso wall consists of an amber onyx frame enhanced by LED technology. Three dimensions available.",
        material: "Amber onyx",
        dimensions: "S: W30 x D4 x H40 cm – M: W35 x D4 x H35 cm – L: W25 x D4 x H50 cm",
        weight: "1 Kg",
        light_source: "LED Strip",
        designer: "Simone Fanciullacci",
      },
      dome_applique: {
        description: "Geometric discipline and minimal style. The iconic lampshade of the Dome wall lamp is made of satin and enamelled glass, available in a selection of colors (orange, blue, pink, white) that can be mixed and matched.",
        material: "Satin and enamelled glass (orange, blue, pink, white)",
        dimensions: "Ø 51 x H17 cm",
        weight: "2 Kg",
        light_source: "LED board 11 W | 24 V | 1340 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      dome_floor: {
        description: "Geometric discipline and minimal style. The iconic lampshade of the Dome floor lamp is made of satin and enamelled glass, available in a selection of colors (orange, blue, pink, white) that can be mixed and  matched.",
        material: "Satin and enamelled glass (orange, blue, pink, white)",
        dimensions: "Ø 51 x H173 cm",
        weight: "9,4 Kg",
        light_source: "LED board 18 W | 24 V | 2240 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      dome_pendant: {
        description: "Geometric discipline and minimal style, the lightness of timeless shapes. A thin metal structure frames the iconic lampshade of the Dome pendant, which is made of enamelled and satin glass, available in a selection of colors (orange, blue, pink, white) that can be mixed and matched.",
        material: "Satin and enamelled glass (orange, blue, pink, white)",
        dimensions: "Ø 51 x H22 cm",
        weight: "4,2 Kg",
        light_source: "LED board 18 W | 24 V | 2240 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      eike: {
        description: "The texture of oak meets the lightness of glass. Eike is a table lamp with a solid oak structure, metal parts in light bronzed brass finish and satin pyrex glass shade.",
        material: "Solid oak structure, metal parts in light bronzed brass finish and satin pyrex glass shade. ",
        dimensions: "Ø 47 x H25 cm",
        weight: "7,5 Kg",
        light_source: "LED module Sirio 24 W | 1560 lmn | 3000 K",
        designer: "G.Luce",
      },
      empire_floor: {
        description: "Geometry and texture meet in an unexpected way, revealing a new taste and unique reflections. Empire is a brass floor lamp in a light satin gold or bronze finish and a percalline lampshade.",
        material: "Brass in a light satin gold or bronze finish. Percalline lampshade.",
        dimensions: "Ø 60 x H186 cm",
        weight: "11,3 Kg",
        light_source: "LED module Sirio 24W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      empire_table: {
        description: "Geometry and texture meet in an unexpected way, revealing a new taste and unique reflections. Empire is a brass table lamp in a light satin gold or bronze finish and a percalline lampshade.",
        material: "Brass in a light satin gold or bronze finish. Percalline lampshade.",
        dimensions: "W28 x D28 x H53 cm",
        weight: "7,4 Kg",
        light_source: "LED module Sirio 24W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      foca_l: {
        description: "Foca frames the silence and the poetry of light in a cone. The LED table lamp features a satin opal plexiglass structure available in three dimensions: S, M, L.",
        material: "Satin opal plexiglass structure",
        dimensions: "W49 x D24 x H46 cm Ø 40",
        weight: "3,5 Kg",
        light_source: "LED Strip",
        designer: "Fém",
      },
      foca_m: {
        description: "Foca frames the silence and the poetry of light in a cone. The LED table lamp features a satin opal plexiglass structure available in three dimensions: S, M, L.",
        material: "Satin opal plexiglass structure",
        dimensions: "W39 x D18 x H36 cm Ø 30",
        weight: "2,3 Kg",
        light_source: "LED Strip",
        designer: "Fém",
      },
      foca_s: {
        description: "Foca frames the silence and the poetry of light in a cone. The LED table lamp features a satin opal plexiglass structure available in three dimensions: S, M, L.",
        material: "Satin opal plexiglass structure",
        dimensions: "W28 x D14 x H24,5 cm Ø 20",
        weight: "1 Kg",
        light_source: "LED Strip",
        designer: "Fém",
      },
      gea_legno: {
        description: "The many facetings of wood along with the wisdom of the master cabinetmaker. Gea is a table lamp in natural brushed oak or in glossy black maple, which is enriched by a conical lampshade in percalline.",
        material: "Natural brushed oak or glossy black maple. Percaline lampshade.",
        dimensions: "Ø 39 x H60 cm",
        weight: "2 Kg",
        light_source: "LED module Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      gea_nera: {
        description: "The many facetings of wood along with the wisdom of the master cabinetmaker. Gea is a table lamp in natural brushed oak or in glossy black maple, which is enriched by a conical lampshade in percalline.",
        material: "Natural brushed oak or glossy black maple. Percaline lampshade.",
        dimensions: "Ø 39 x H60 cm",
        weight: "2 Kg",
        light_source: "LED module Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      generator: {
        description: "New ways of attention to details. Generator is a suspension lamp made of a satin plexiglass structure and brass hemispheres in bronze finish. It features a brass ceiling bracket and bronze details. Also available in floor and table versions.",
        material: "Satin plexiglass structure and brass hemispheres in bronze finish. Brass ceiling bracket and bronze details.",
        dimensions: "W130 x D15 x H15 cm",
        weight: "-",
        light_source: "LED Strip",
        designer: "Simone Fanciullacci",
      },
      ivy: {
        description: "The movement of the future. Ivy is a floor lamp with a light source distributed on two levels. Available in a steel structure provides lightness to the structure.",
        material: "Steel structure in light satin gold finish with Emperador marble or steel structure in satin bronze finish with Calacatta gold marble.",
        dimensions: "Ø 40 x H182 cm",
        weight: "11,2 Kg",
        light_source: "LED module Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      lucciola_m: {
        description: "The gracefulness of light. Lucciola is a table lamp with structure and metal parts in a light bronzed brass finish and satin pirex glass diffuser. Available in two dimensions: M, S.",
        material: "Structure and metal parts in a light bronzed brass finish and satin pirex glass diffuser.",
        dimensions: "Ø 15 x H25 cm",
        weight: "2,4 Kg",
        light_source: "LED module Sirio 24 W | 1560 lmn | 3000 K",
        designer: "G.Luce",
      },
      lucciola_s: {
        description: "The gracefulness of light. Lucciola is a table lamp with structure and metal parts in a light bronzed brass finish and satin pirex glass diffuser. Available in two dimensions: M, S.",
        material: "Structure and metal parts in a light bronzed brass finish and satin pirex glass diffuser.",
        dimensions: "Ø 15 x H18 cm",
        weight: "1,2 Kg",
        light_source: "LED module Sirio 24 W | 1560 lmn | 3000 K",
        designer: "G.Luce",
      },
      naica: {
        description: "The gathering of light, metal and stone. Naica is a table lamp which features a steel structure in a light satin gold finish and a tiger onyx diffuser, or in satin black nickel finish and amber onyx diffuser.",
        material: "Steel structure in a light satin gold finish and a tiger onyx diffuser, or in satin black nickel finish and amber onyx diffuser",
        dimensions: "W21 x D19 x H36,5 cm",
        weight: "4,2 Kg",
        light_source: "LED module Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci",
      },
      norma: {
        description: "A totem of full and empty volumes, characterized by the rhythm of simple geometric elements. Norma is a floor lamp with a steel structure available in light satin gold, nickel or black nickel finishes. The base in Anthracite or Calacatta Oro marble contrasts the lightness of a lampshade made in two fabrics. Also available in suspension and floor versions.",
        material: "Steel structure available in light satin gold, nickel or black nickel finishes. Base in Anthracite or Calacatta Oro marble. Lampshade made in two fabrics.",
        dimensions: "Ø 51 x H180 cm",
        weight: "6 Kg",
        light_source: "LED module Sirio 24 W | 1560 lmn | 3000 K",
        designer: "G.Luce",
      },
      tour_abatjour: {
        description: "Abat-jour with a refined yet unpretenscious line and fine finishes, a perfect match for any type of environment. Tour is generated by the harmony between a slim body in natural brushed oak and its enveloping percalline lampshade.",
        material: "Natural brushed oak. Percalline lampshade.",
        dimensions: "W38 x D26 x H48 cm",
        weight: "1,2 Kg",
        light_source: "LED module 18 W | 2040 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      tour_floor: {
        description: "Floor lamp with a refined yet unpretenscious line and fine finishes, a perfect match for any type of environment. Tour is generated by the harmony between a slim body in natural brushed oak and its enveloping percalline lampshade.",
        material: "Natural brushed oak. Percalline lampshade.",
        dimensions: "W75 x D49 x H174 cm",
        weight: "8,6 Kg",
        light_source: "LED module 42 W | 4760 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      tour_table: {
        description: "Table lamp with a refined yet unpretenscious line and fine finishes, a perfect match for any type of environment. Tour is generated by the harmony between a slim body in natural brushed oak and its enveloping percalline lampshade.",
        material: "Natural brushed oak. Percalline lampshade.",
        dimensions: "W50 x D37 x H65 cm",
        weight: "3,2 Kg",
        light_source: "LED module 24 W | 2720 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      zena_abatjour: {
        description: "The discreet triumph of light. Zena is a abat-jour with a slender brass structure in light satin gold or black nickel finish. The lampshade is in opal glass.",
        material: "Brass structure in light satin gold or black nickel finish. Lampshade in opal glass.",
        dimensions: "W24 x D22 x H41 cm",
        weight: "3 Kg",
        light_source: "LED module Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      zena_floor: {
        description: "The discreet triumph of light. Zena is a floor lamp with a slender brass structure in light satin gold or black nickel finish. The lampshade is in opal glass.",
        material: "Brass structure in light satin gold or black nickel finish. Lampshade in opal glass.",
        dimensions: "W43 x D43 x H164 cm",
        weight: "12,5 Kg",
        light_source: "LED module Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      },
      zena_table: {
        description: "The discreet triumph of light. Zena is a table lamp with a slender brass structure in light satin gold or black nickel finish. The lampshade is in opal glass.",
        material: "Brass structure in light satin gold or black nickel finish. Lampshade in opal glass.",
        dimensions: "W36 x D34 x H59 cm",
        weight: "7,5 Kg",
        light_source: "LED module Sirio 24 W | 1560 lmn | 3000 K",
        designer: "Simone Fanciullacci | Antonio de Marco",
      }
    },
    related: {
      heading: "Related products"
    }
  },
  products: {
    collections: {
      all: "all",
      0: "floor",
      1: "table",
      2: "abatjour",
      3: "pendant",
      4: "applique",
    }
  },
  custom: {
    intro: {
      heading: "Explore G.Luce different customization and services levels:",
      1: {
        heading: "ADAPTATION OF CATALOGUE PRODUCTS",
        text: "Possibility to customize all the products of the G.Luce Collections with personalized finishes and light sources.",
      },
      2: {
        heading: "BESPOKE UNIQUE PRODUCTION",
        text: "Each device is designed according to the customer's needs and to the features decided during the product development steps."
      },
      3: {
        heading: "CONSULTING AND DESIGNING",
        text: "A network of specialized partners are joining working in the development process with technical suggestion and operative assets."
      },
      4: {
        heading: "CUSTOMER CARE",
        text: "A complete process created to always accompany and assist the customers' needs through time"
      },
    },
    why: {
      heading: "Why G.Luce bespoke",
      tl: {
        title: "Know how",
        text: "Knowledge of materials, the most innovative techniques and production facilities.",
      },
      tr: {
        title: "Networking",
        text: "Partnerships developed over thirty years of experience with specialized companies and master craftsmen from all over Italy.",
      },
      dl: {
        title: "Experience",
        text: "Hotels and restaurants, boutique, cruise liners, offices and residential projects.",
      },
      dr: {
        title: "Customer care",
        text: "Complete assistance for our customers in all project phases up to the after-sales assistance",
      },
    },
  },
  network: {
    heading: "Network",
    text: "G.Luce is the result of a thirty-year history of commitment, research and collaboration with a network of professional experts. The selection of materials and the finest techniques of traditional craftsmanship have always laid the foundations for help solving the real needs of customers in a functional and efficient way.",
    blacksmith: {
      heading: "The blacksmith",
      text: "Each light tells a story of impeccable techniques. Knowledge of materials requires a refined exercise, over the years G.Luce established deep relationships and ties with great master craftsmen and specialized companies from all over Italy. A network of people and technical expertise supported by resources and structures suitable for large-scale productions",
    },
    glazier: {
      heading: "The glazier",
      text: "G.Luce developed project-oriented collaborations with young international designers over the years. In an exchange that enriches both sides, G.Luce is able to monitor the new frontiers of design and evolve its creations according to new stimuli, ideas and perspectives",
    },
    straw_blower: {
      heading: "The staw blower",
      text: "Thanks to the experiences and relationships gained from participation in international trade fairs and working on prestigious projects, G.Luce has built a network of professional interior designers and architectural firms. The Continuous dialogue with different realities has allowed to create a boundless taste, characterized by the finest materials and cutting-edge techniques declined according to a balanced minimal elegance.",
    },
  },
  contact: {
    welcome: "Welcome to G.Luce Customer Service.",
    info: "You can use this session to send us your requests about our products.",
    call_to_action: "Contact us",
    heading: "Contact",
    numero_verde: "Numero Verde",
    form: {
      first_name: "First Name*",
      last_name: "Last Name*",
      company: "Company",
      country: "Country",
      city: "City",
      subject: "Subject",
      message: "Message",
      contact_mode: {
        heading: "Preferred contact mode",
        email: "Email (required field)",
        phone: "Phone (required field)",
      },
      email: "Email*",
      phone: "Phone",
      type: {
        heading: "User Type",
        professional: "Architect/Interior designer",
        personal: "Personal",
      },
      privacy: {
        heading: "Privacy",
        text: "Ai sensi della normativa privacy, i dati personali saranno trattati da G.Luce S.p.A nel rispetto della nostra Politica di protezione dei dati personali. In qualsiasi momento, l'utente può esercitare i propri diritti o revocare i consensi eventualmente prestati, anche inviando una mail a info@gluce.i",
      },
      acceptance: {
        heading: "Ho preso visione dell’informativa per il trattamento dati personali di G.Luce S.p.A.",
        activities: "acconsento alle attività di marketing di G.Luce S.p.A.",
        profiling: "acconsento alle attività di profilazione",
        marketing: "acconsento al marketing di G.Luce S.p.A.",
      },
      marked: "Fields marked with (*) are mandatory",
      confirm: "Thanks! Message submitted",
      submit: "Submit",
      policy: "Pursuant to Code regarding the protection of personal data (Legislative Decree 30 June 2003, no.196), adapted to the provisions of the General Data Protection Regulation “GDPR” (EU) 2016/679 through Legislative Decree 10 August 2018, no.101, all information sent through this form will be kept strictly confidential, with the utmost security, will not be transferred to third parties and at any time you can request to modify or delete them by writing for attention of the data controller:",
      authorize: "Authorize"
    }
  },
  tech: {
    heading: "SIRIO",
    text: "SIRIO, the lighting source with innovative LED technology. A revolutionary lighting system aimed at easily replacing light sources in an agile and simple way: the interchangeable boards combine a highly efficient performance with the advantage of a quick installation.",
    section1: "Each single board is fixed to the structure on two points and can be easily removed and replaced. The possibility of replacing a single board – while maintaining the entire module - contributes significantly to environmental protection by reducing electronic waste.",
    section2: "G.Luce patents an innovative and technological LED lighting system to ensure an always operative environment. The module is created to meet the efficiency and quality standards of prestigious hôtellerie and naval realities. The dimmable function is giving the same light intensity, color and light output of the incandescent bulbs, enfolding every room in a harmonious and warm atmosphere",
    section3: "The Sirio module project is applied to the entire G.Luce Collection. The most suitable lighting solutions are designed to meet simplicity, energy saving and ecological standards that distinguish each product of the collection, as well as any single bespoken request.",
  },
  collections: {
    heading: "COLLECTIONS",
    text: "Our stories enrich with light and timeless elegance every space. Classic lines and the most valuable materials meld with sustainable and efficient technologies to meet any design need. Designs that can tell intimacy and know how to light up the scene.",
    section1: {
      heading: "Masterpieces",
      text: "These models live in residential environments and exclusive structures around the world. Unique designs made with the most careful selection of materials. Lines recalling famous stylistic figures, now declined according to functional solutions and minimal tendencies.",
    },
    section2: {
      heading: "Classics",
      text: "Traditional pieces characterized by timeless shapes. The classic design observed through the lenses of a modern sensibility become the solutions for a new way of inhabiting a space. Pieces of furniture enriched by refined materials and illuminated by the Sirio technology",
    },
    section3: {
      heading: "Fronteers",
      text: "G.Luce natural attitude in search of new technical solutions. The continuous relationship and dialogue with international realities meets original approaches and emerging designers, giving shape to unique light sources",
    },
    section5: "All G.Luce collections can be customized: materials and dimensions can vary according to specific request. Our dedicated consulting service will help find new design solutions and give chance to rewarding partnerships.",
  },
  contract: {
    section1: {
      heading: "The project challenge",
      text: "A widespread network of exclusive and specialized suppliers and subsuppliers along with collaboration with architectural firms, general contractors, construction companies or shipyards help us to develop and implement lighting systems either by using large scale standard products, possibly modified on specific needs, or creating tailor made products.",
    },
    section2: "CRAFTSMANSHIP, CULTURE OF INDUSTRIAL PRODUCTION, PASSION FOR EXPERIMENTATION AND INNOVATION, DEEP KNOWLEDGE AND CAREFUL RESEARCH OF MATERIALS, THESE ALL ARE THE HALLMARKS OF G.LUCE'S CONTRACT SERVICE",
    section3: "Flexibility, high craftsmanship and attention to detail, which distinguish G.Luce, are combined with a strong tendency to keep sourcing for unique solutions, and a continuous renewal.",
  },
  not_found: {
    heading: "Page not found"
  }
}

export default en;