import { LangContext } from "context";
import { Lang } from "context/lang";
import { useContext } from "react";
import en from "./en";
import it from "./it";
import ru from "./ru";

const langs = {
  it,
  en,
  ru,
}

export function useLang() {
  const { ctx } = useContext(LangContext);
  return function t(key: string) {
    let obj: any = langs[ctx.lang as Lang];
    key.split(".").forEach(k => {
      obj = obj[k]
    })
    return obj;
  }
}

export interface I18N {
  menu: {
    product: string;
    collections: string;
    tech: string;
    network: string;
    custom: string;
    contract: string;
    contact: string;
  },
  footer: {
    cookie: string;
    privacy: string;
    legal: string;
    contact: string;
    download: string;
  }
  call_to_action: {
    contact_us: string;
  },
  home: {
    products: {
      heading: string;
      heading2: string;
      button: string;
    },
    areas: {
      discover: string;
      custom: string;
      custom_sub: string;
    },
    contacts: {
      gluce: {
        title: string;
        gluce: string;
        street: string;
        city: string;
        country: string;
        phone: string;
        fax: string;
        email: string;
        numero_verde: string;
      },
    }
  },
  product: {
    specifications: string;
    material: string;
    dimensions: string;
    weight: string;
    light_source: string;
    designer: string;
    datasheet: string;
    info: {
      agua_applique: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      agua_pendant: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      agua_pendant_3: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      aura_floor: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      aura_table: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      balestra: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      bridget_applique: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      bridget_floor: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      bridget_table: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      bridget_table_s: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      calipso_table: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      calipso_wall: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      dome_applique: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      dome_floor: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      dome_pendant: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      eike: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      empire_floor: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      empire_table: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      foca_l: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      foca_m: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      foca_s: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      gea_legno: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      gea_nera: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      generator: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      ivy: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      lucciola_m: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      lucciola_s: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      naica: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      norma: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      tour_abatjour: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      tour_floor: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      tour_table: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      zena_abatjour: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      zena_floor: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
      zena_table: {
        description: string;
        material: string;
        dimensions: string;
        weight: string;
        light_source: string;
        designer: string;
      }
    },
    related: {
      heading: string;
    }
  },
  products: {
    collections: {
      all: string;
      0: string;
      1: string;
      2: string;
      3: string;
      4: string;
    }
  },
  custom: {
    why: {
      heading: string;
      tl: {
        title: string;
        text: string;
      },
      tr: {
        title: string;
        text: string;
      },
      dl: {
        title: string;
        text: string;
      },
      dr: {
        title: string;
        text: string;
      },
    },
    intro: {
      heading: string;
      1: {
        heading: string;
        text: string;
      },
      2: {
        heading: string;
        text: string;
      },
      3: {
        heading: string;
        text: string;
      },
      4: {
        heading: string;
        text: string;
      },
    };
  },
  network: {
    heading: string;
    text: string;
    blacksmith: {
      heading: string;
      text: string;
    },
    glazier: {
      heading: string;
      text: string;
    },
    straw_blower: {
      heading: string;
      text: string;
    },
  },
  contact: {
    welcome: string;
    info: string;
    call_to_action: string;
    heading: string;
    numero_verde: string;
    form: {
      first_name: string;
      last_name: string;
      company: string;
      country: string;
      city: string;
      subject: string;
      message: string;
      contact_mode: {
        heading: string;
        email: string;
        phone: string;
      },
      email: string;
      phone: string;
      type: {
        heading: string;
        professional: string;
        personal: string;
      },
      privacy: {
        heading: string;
        text: string;
      },
      acceptance: {
        heading: string;
        activities: string;
        profiling: string;
        marketing: string;
      },
      marked: string;
      confirm: string;
      submit: string;
      policy: string;
      authorize: string;
    }
  },
  tech: {
    heading: string;
    text: string;
    section1: string;
    section2: string;
    section3: string;
  },
  collections: {
    heading: string;
    text: string;
    section1: {
      heading: string;
      text: string;
    },
    section2: {
      heading: string;
      text: string;
    },
    section3: {
      heading: string;
      text: string;
    },
    section5: string;
  },
  contract: {
    section1: {
      heading: string;
      text: string;
    },
    section2: string;
    section3: string;
  },
  not_found: {
    heading: string;
  }
}