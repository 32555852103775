import React, { useReducer, createContext } from "react";

export interface ReducerAction {
  type: string;
  payload: any;
}

export type Lang = "it" | "en";
const DEFAULT: Lang = "it";

interface State {
  lang: Lang;
}

const storedLang = localStorage.getItem("lang")?.replaceAll('"', "");
const initialLang: State = {
  lang: (storedLang as Lang) ?? DEFAULT,
};

export const LangContext = createContext<any>(initialLang);

function langReducer(
  state: State = initialLang,
  { type, payload }: ReducerAction
): State {
  switch (type) {
    case "LANG":
      return {
        lang: payload,
      };
    default:
      return state;
  }
}

export function LangProvider(props: any) {
  const [ctx, dispatch] = useReducer(langReducer, initialLang);
  const value = { ctx, dispatch };

  return (
    <LangContext.Provider value={value as any}>
      {props.children}
    </LangContext.Provider>
  );
}
